<template>

</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "clearCookie",
  mounted(){
    this.setUserToken("");
    this.setUserName("");
    this.setUserAccountType(-1);
    this.setUserMbrMemberId("");
    this.setUserId("");
    this.setUserEnterpriseState("");
    this.$cookies.set("token","",null,null,".lygstrim.com")
    this.$router.push({
      path:'/'
    })
  },
  methods:{
    ...mapMutations({
      setUserToken: 'userStore/setUserToken',
      setUserName: 'userStore/setUserName',
      setUserAccountType: 'userStore/setUserAccountType',
      setUserMbrMemberId: 'userStore/setUserMbrMemberId',
      setUserId: 'userStore/setUserId',
      setUserEnterpriseState: 'userStore/setUserEnterpriseState'
    }),
  }
}
</script>

<style scoped>

</style>